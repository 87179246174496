
.container {
  padding-bottom: 20px;
}
.search {
  transition: all 0.5s;
  max-width: 30%;
  padding-top: 20px;
}
.search:focus-within {
  max-width: 50%;
}
.search-results {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 20px;
}
.search-image {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.notfound {
    padding-top: 100px;
  }
}
.tag {
  .icon {
    margin-left: 5px !important;
  }
}
